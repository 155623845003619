
.steps-wrapper {
    margin-top: 34px;
}
.education-wrapper {
    padding: 30px;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    /deep/ .el-scrollbar__view {
        /*height: 100%;*/
        display: flex;
        flex-direction: column;
    }
    .education-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .el-breadcrumb {
            line-height: 40px;
        }
    }
    .create-content {
        margin: 60px 0 30px;
        height: calc(100% - 56px);
        .success-content {
            margin-top: 155px;
            text-align: center;
            .iconfont {
                color: #1FD18C;
                font-size: 52px;
            }
            .text {
                font-size: 24px;
                margin: 20px 0 0;
                font-weight: bold;
            }
        }
    }
}
.el-link + .el-link {
    margin-left: 10px;
}
.bottom-btn {
    text-align: center;
}
/deep/ .el-form-item__label {
    color: #14141C;
}
.search-item {
    margin: 30px 0;
    .search-input {
        .search-ad-form-item {
            margin-top: 29px;
        }
        .item-top {
            margin-bottom: 9px;
        }
        .item-bottom {
            margin-top: 0;
        }
    }
    .search-column {
        display: flex;
        justify-content: start;
        .search-column-one {
            width: 274px;
            min-width: 274px;
            margin-right: 30px;
            &:nth-child(2) {
                margin-right: 0;
            }
        }
    }
    .search-input-title {
        display: flex;
        justify-content: start;
        margin-top: 27px;
        .text {
            display: block;
            width: 270px;
            margin-right: 30px;
            text-align: center;
            &:nth-child(1) {
                margin-left: 42px;
            }
            &:nth-child(2) {
                width: 195px;
                margin-right: 46px;
            }
            &:nth-child(3) {
                width: 247px;
            }
        }
    }
}
.search-item-title {
    display: flex;
    align-items: center;
    color: #1222D8;
    font-weight: 500;
    font-size: 16px;
    i {
        margin-right: 10px;
    }
    span.text {
        flex: 1;
        width: 1%;
        display: block;
        border-bottom: 1px solid #DCDCDC;
        line-height: 2;
    }
}
.generate-data {
    .generate-form {
        .search-ad-form-item {
            margin-bottom: 18px;
            width: 270px;
            &:nth-child(1) {
                margin-bottom: 0;
            }
            span.time {
                display:block;
                width: 160px;
                text-align: center;
            }
        }
    }
    .generate-form-item {
        .generate-item {
            display: flex;
            flex-direction: column;
        }
    }
}
.sue-official-data {
    font-weight: 500;
    .sue-sd-text {
        width: 122px;
        display: inline-block;
        text-align: right;
        color: #14141C;
        padding-right: 12px;
        box-sizing: border-box;
    }
}
.input-warning {
    /deep/ .el-input__inner {
        border: 1px solid #E21D1A;
    }
}
.generate-i:nth-child(2) {
    .search-ad-form-item {
        width: 210px;
        /deep/ .el-form-item__content {
            margin-left: 30px !important;
        }
    }
}
.generate-i:nth-child(3) {
    .search-ad-form-item {
        width: 210px;
        /deep/ .el-form-item__content {
            margin-left: 30px !important;
        }
    }
}
.generate-i:nth-child(4) {
    .search-ad-form-item {
        width: 210px;
        /deep/ .el-form-item__content {
            margin-left: 30px !important;
        }
    }
}
.generate-i:nth-child(5) {
    .search-ad-form-item {
        width: 210px;
        /deep/ .el-form-item__content {
            margin-left: 30px !important;
        }
    }
}
.generate-i:nth-child(6) {
    .search-ad-form-item {
        width: 210px;
        /deep/ .el-form-item__content {
            margin-left: 30px !important;
        }
    }
}
.search-item .search-column .store-item {
    width: 290px;
    min-width: 290px;
}

.search-item .store-item .search-input .item-top {
    margin-top: 15px;
}

.search-item .store-item .store-title {
    margin-top: 20px;
    margin-bottom: 0;
    /deep/ .el-form-item__content {
        text-align: center;
        width: 160px;
    }
}
